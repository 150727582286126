<template>
    <div class="pgoffice-page">
        <input name="orderId" class="pg-input" id="orderId" type="text" :style="{ display: 'none' }" style="width: 180px"
            v-model="params.orderId" />
        <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
    </div>
</template>
  
<script>
import { simpleWord } from "@/api/pageOffice";

export default {
    name: "editor_word",
    data: function () {
        return {
            html_code: "",
            params: "",
        };
    },
    methods: {
        Save: function () {
            document.getElementById("PageOfficeCtrl1").WebSave();
            let result = document.getElementById("PageOfficeCtrl1").CustomSaveResult;
            window.external.CallParentFunc(
                "PageOfficeCbFn('" + encodeURIComponent(result) + "')"
            );
            window.external.close();
        },
        Close: function () {
            window.external.CallParentFunc("PageOfficeCloseFn()");
            window.external.close();
        },
        getParam: function () {
            try {
                this.params = window.external.UserParams;
                if (this.params) {
                    this.params = JSON.parse(decodeURIComponent(this.params));
                }
            } catch (error) {
                alert(error);
            }

        },
        init: function () {
            this.getParam();
            if (!this.params || this.params == "" || this.params == undefined) {
                alert("未获取到参数,打开失败");
                return;
            }
            simpleWord(this.params).then((res) => {
                this.html_code = res;
            });
        },

        ShowDefineDataRegions() {
            document.getElementById("PageOfficeCtrl1").ShowHtmlModelessDialog("/PgOffice/DataRegionDlg", "parameter=xx", "left=300px;top=390px;width=520px;height=410px;");
        },

        //获取后台添加的书签名称字符串
        getBkNames() {
            var bkNames = document.getElementById("PageOfficeCtrl1").DataRegionList.DefineNames;
            return bkNames;
        },
        //获取后台添加的书签文本字符串
        getBkConts() {
            var bkConts = document.getElementById("PageOfficeCtrl1").DataRegionList.DefineCaptions;
            return bkConts;
        },
        //定位书签
        locateBK(bkName) {
            var drlist = document.getElementById("PageOfficeCtrl1").DataRegionList;
            drlist.GetDataRegionByName(bkName).Locate();
            document.getElementById("PageOfficeCtrl1").Activate();
            window.focus();
        },
        //添加书签
        addBookMark(param) {
            var tmpArr = param.split("=");
            var bkName = tmpArr[0];
            var content = tmpArr[1];
            var drlist = document.getElementById("PageOfficeCtrl1").DataRegionList;
            drlist.Refresh();
            try {
                document.getElementById("PageOfficeCtrl1").Document.Application.Selection.Collapse(0);
                drlist.Add(bkName, content);
                return "true";
            } catch (e) {
                return "false";
            }
        },
        //删除书签
        delBookMark(bkName) {
            var drlist = document.getElementById("PageOfficeCtrl1").DataRegionList;
            try {
                drlist.Delete(bkName);
                return "true";
            } catch (e) {
                return "false";
            }
        },
        //遍历当前Word中已存在的书签名称
        checkBkNames() {
            var drlist = document.getElementById("PageOfficeCtrl1").DataRegionList;
            drlist.Refresh();
            var bkName = "";
            var bkNames = "";
            for (var i = 0; i < drlist.Count; i++) {
                bkName = drlist.Item(i).Name;
                bkNames += bkName.substr(3) + ",";
            }
            return bkNames.substr(0, bkNames.length - 1);
        },
        //遍历当前Word中已存在的书签文本
        checkBkConts() {
            var drlist = document.getElementById("PageOfficeCtrl1").DataRegionList;
            drlist.Refresh();
            var bkCont = "";
            var bkConts = "";
            for (var i = 0; i < drlist.Count; i++) {
                bkCont = drlist.Item(i).Value;
                bkConts += bkCont + ",";
            }
            return bkConts.substr(0, bkConts.length - 1);
        }
    },
    created: function () {
        window.BeforeDocumentSaved = function (e) { };
        window.Save = this.Save;
        window.Close = this.Close;
        window.ShowDefineDataRegions = this.ShowDefineDataRegions;
        window.getBkNames = this.getBkNames;
        window.getBkConts = this.getBkConts;
        window.locateBK = this.locateBK;
        window.addBookMark = this.addBookMark;
        window.delBookMark = this.delBookMark;
        window.checkBkNames = this.checkBkNames;
        window.checkBkConts = this.checkBkConts;

        this.init();
    },
};
</script>
  